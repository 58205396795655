import React, { Component } from "react"

import SEO from "../components/seo"
import SiteContext from "../context/SiteContext"
import Layout from "../components/layout"
import Sidebar from "../components/Sidebar"
import SectorSelect from "../components/SectorSelect"
import ApplicationSelect from "../components/ApplicationSelect"
import AttributeSelect from "../components/AttributeSelect"
import ProductList from "../components/ProductList"
import headerArrows from '../images/header-arrows.png'
import lowDustImgFlow from '../images/low_dust_masterflow.jpg'
import flowIntroImage from '../images/intro_image_masterflow.png'

import Airtable from 'airtable'

const base = new Airtable({ apiKey: 'patUtY9aIKNiOkjaV.d9fbb696d1713dee827d655269f8e9ac87f3045d831696fd70b55620cf053cb1' }).base('app4Oove6n2IAghLj');

class WelcomeMasterFlow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeStep: 'sector',
            showSectorSelect: false,
            showApplicationSelect: false,
            showAttributeSelect: false,
            showProductPage: false,
            products: [],
            sectors: [],
            allApplications: [],
            applications: [],
            attributes: [],
            selectedSector: '',
            selectedApplications: [],
            selectedAttributes: [],
            availableProducts: [],
            sectorNextEnabled: 'disabled',
            applicationNextEnabled: 'disabled',
            attributeNextEnabled: 'disabled',
            atLimit: false,
            site: 'masterflow',
            siteTitle: 'Master Builders Solutions Precision Industrial Grouts',
            introContent: 'The Industrial and Heavy Civil construction markets are abundant with applications where alignment, support, and protection of key structural members and equipment are of paramount concern. Using the MasterFlow product selector tool you can quickly find a solution for you specific application and approximate the number of bags required. With over 100 years of experience in the formulation and application of precision grouts, you can trust our business and products to support your next project.',
            stepOneText: 'Step 1: Choose the sector',
            stepTwoText: 'Step 2: Choose the application(s)',
            sidebarGroupOneTitle: 'Sector',
            sidebarGroupTwoTitle: 'Application',
            sidebarGroupThreeTitle: 'Product Attributes',
            lowDustBoxTitle: 'Interested in our Low Dust Technology?',
            lowDustBoxContent: 'MasterFlow grouts offer the widest portfolio of products that feature low dust aggregate, limiting the presence of harmful respirable silica, and enhancing job site cleanliness. Click the link to learn more about our low dust products like MasterFlow 648.',
            lowDustBoxLink: 'https://mbcc.sika.com/en-us/products/grouts',
            attributeSelectTitle: 'Step 3: Choose up to 5 product attributes',
        }
    }

    async componentDidMount() {
        // Get all product data from airtable

        try {
            base('Sectors').select({ view: 'All Sectors' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const sectors = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });

                        this.setState({
                            sectors,
                        });
                        fetchNextPage();
                    }
                );

            base('Applications').select({ view: 'All Applications' })
                .eachPage(
                    (records, fetchNextPage) => {
                        this.setState({
                            allApplications: records,
                        });
                        fetchNextPage();
                    }
                );

            base('Attributes').select({ view: 'All Attributes' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const attributes = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });

                        this.setState({
                            attributes
                        });
                        fetchNextPage();
                    }
                );

            base('Products').select({ view: 'All Products' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const products = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });

                        this.setState({
                            products,
                            showSectorSelect: true
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }

        // Get list of sectors from product data

    }

    addSector = sector => {
        const sectorObj = this.state.sectors.find(el => el.fields.Name === sector)
        const applicationIds = sectorObj.fields.Applications
        const applications = this.state.allApplications.filter(application => applicationIds.includes(application.id))

        const sortedApplications = applications.sort(function (a, b) {
            if (a.fields.Name > b.fields.Name) return 1;
            if (a.fields.Name < b.fields.Name) return -1;
            return 0;
        });

        this.setState({ 
            selectedSector: sector,
            sectorNextEnabled: '',
            applications: sortedApplications
        })
    }

    addApplications = application => {
        let newApplications = []
        if (this.state.selectedApplications.some(item => item === application)) {
            newApplications = this.state.selectedApplications.filter(function (el) { return el !== application; });
        } else {
            newApplications = [...this.state.selectedApplications, application]
        }

        this.setState({ 
            selectedApplications: newApplications,
            applicationNextEnabled: '',
        })
    }

    isTrue = (arr, arr2) => {
        return arr.every(i => arr2.includes(i));
    }

    addAttributes = attributeObj => {
        const attribute = {
            name: attributeObj.fields.Name,
            id: attributeObj.id
        }

        let newAttributes = []
        let atLimit = this.state.atLimit
        if (!atLimit) {
            if (this.state.selectedAttributes.some(item => item.id === attribute.id)) {
                newAttributes = this.state.selectedAttributes.filter(function (el) { return el.id !== attribute.id; });
                if (newAttributes.length === 5) {
                    atLimit = true
                } else {
                    atLimit = false
                }
            } else {
                newAttributes = [...this.state.selectedAttributes, attribute]
                if (newAttributes.length === 5) {
                    atLimit = true
                } else {
                    atLimit = false
                }
            }
            
            this.setState({ 
                selectedAttributes: newAttributes,
                attributeNextEnabled: '',
                atLimit
            }, () => {

                const availableProducts = []
                if (this.state.selectedAttributes.length) {
                    const applicationLookupField = `Lookup for ${this.state.selectedSector} - Application`
                    this.state.products.forEach(product => {
                        const productAttrs = product.fields['Lookup for Attributes']
                        const productSectors = product.fields['Lookup for Sectors']
                        // From https://josephkhan.me/check-if-an-array-is-a-subset-of-another-array-in-javascript/
                        const attrsObj = {}
                        productAttrs.forEach((el, index) => {
                            attrsObj[el] = index;
                        });
                        
                        var productHasAttributes = this.state.selectedAttributes.every((el) => {
                            return attrsObj[el.name] !== undefined; //because 0 is falsy
                        });

                        const productApplications = product.fields[applicationLookupField]

                        if (productApplications) {
                            const appsObj = {}
                            productApplications.forEach((el, index) => {
                                appsObj[el] = index;
                            });
                            
                            var productHasApplications = this.state.selectedApplications.every((el) => {
                                return appsObj[el] !== undefined; //because 0 is falsy
                            });
                        }

                        const productHasSector = productSectors.includes(this.state.selectedSector)
                        if (productHasSector && productHasAttributes && productHasApplications) {
                            availableProducts.push(product)
                        }
                        this.setState({ availableProducts })
                    })
                } else {
                    this.setState({ availableProducts: [] })
                }
            })
        } else {
            if (this.state.selectedAttributes.some(item => item.id === attribute.id)) {
                newAttributes = this.state.selectedAttributes.filter(function (el) { return el.id !== attribute.id; });

                this.setState({
                    selectedAttributes: newAttributes,
                    attributeNextEnabled: '',
                    atLimit: false
                })
            }
        }
    }

    handleNextClick = step => {
        switch (step) {
            case 'application':
                this.setState({
                    activeStep: 'application',
                    showSectorSelect: false,
                    showApplicationSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                })
                break;
            case 'attribute':
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                })
                break;
            case 'product':
                this.setState({
                    activeStep: 'product',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAttributeSelect: false,
                    showProductPage: true,
                })
                break;
            default:
                break;
        }
    }

    handleBackClick = step => {
        switch (step) {
            case 'sector':
                this.setState({
                    activeStep: 'sector',
                    showSectorSelect: true,
                    showApplicationSelect: false,
                    showAttributeSelect: false,
                    selectedApplications: [],
                    selectedAttributes: [],
                    availableProducts: [],
                })
                break;
            case 'application':
                this.setState({
                    activeStep: 'application',
                    showSectorSelect: false,
                    showApplicationSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                    selectedAttributes: [],
                    availableProducts: [],
                })
                break;
            case 'attribute':
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                })
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <div className="site-masterflow">
                        <Layout>
                            <SEO title="MasterFlow Grout Selector Tool" description="The MasterFlow Grout Selector Tool helps you find the best precision grouting solutions to meet your project needs in just a few clicks." />
                            <div className="wrapper wrapper--tool">
                                <Sidebar 
                                    selectedSector={this.state.selectedSector} 
                                    selectedApplications={this.state.selectedApplications} 
                                    selectedAttributes={this.state.selectedAttributes} 
                                    availableProducts={this.state.availableProducts}
                                    activeStep={this.state.activeStep}
                                    site={this.state.site}
                                    sidebarGroupOneTitle={this.state.sidebarGroupOneTitle}
                                    sidebarGroupTwoTitle={this.state.sidebarGroupTwoTitle}
                                    sidebarGroupThreeTitle={this.state.sidebarGroupThreeTitle}
                                />
                                <div className="content-wrapper">
                                    
                                    {this.state.showSectorSelect 
                                        ? <SectorSelect 
                                            sectors={this.state.sectors} 
                                            addSector={this.addSector} 
                                            selectedSector={this.state.selectedSector} 
                                            handleNextClick={this.handleNextClick} 
                                            handleBackClick={this.handleBackClick}
                                            sectorNextEnabled={this.state.sectorNextEnabled}
                                            availableProducts={this.state.availableProducts}
                                            products={this.state.products}
                                            site={this.state.site} 
                                            siteTitle={this.state.siteTitle}
                                            introContent={this.state.introContent}
                                            introImage={flowIntroImage}
                                            stepOneText={this.state.stepOneText}
                                            lowDustBoxTitle={this.state.lowDustBoxTitle}
                                            lowDustBoxContent={this.state.lowDustBoxContent}
                                            lowDustBoxImage={lowDustImgFlow}
                                            lowDustBoxLink={this.state.lowDustBoxLink}
                                            next="application"
                                        /> 
                                        : null}

                                    {this.state.showApplicationSelect 
                                        ? <ApplicationSelect 
                                            applications={this.state.applications}
                                            addApplications={this.addApplications} 
                                            selectedSector={this.state.selectedSector} 
                                            selectedApplications={this.state.selectedApplications} 
                                            handleNextClick={this.handleNextClick} 
                                            handleBackClick={this.handleBackClick}
                                            stepTwoText={this.state.stepTwoText}
                                            applicationNextEnabled={this.state.applicationNextEnabled}
                                            availableProducts={this.state.availableProducts} 
                                            products={this.state.products}
                                            next="attribute"
                                            previous="sector"
                                        /> 
                                        : null}

                                    {this.state.showAttributeSelect 
                                        ? <AttributeSelect 
                                            attributes={this.state.attributes}
                                            addAttributes={this.addAttributes} 
                                            selectedSector={this.state.selectedSector}
                                            selectedApplications={this.state.selectedApplications} 
                                            selectedAttributes={this.state.selectedAttributes} 
                                            handleNextClick={this.handleNextClick} 
                                            handleBackClick={this.handleBackClick}
                                            attributeNextEnabled={this.state.attributeNextEnabled}
                                            availableProducts={this.state.availableProducts} 
                                            atLimit={this.state.atLimit}
                                            site={this.state.site}
                                            products={this.state.products}
                                            attributeSelectTitle={this.state.attributeSelectTitle}
                                            next="product"
                                            previous="application"
                                        /> 
                                        : null}

                                    {this.state.showProductPage
                                        ? <ProductList
                                            availableProducts={this.state.availableProducts}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            selectedSector={this.state.selectedSector}
                                            selectedApplications={this.state.selectedApplications} 
                                            selectedAttributes={this.state.selectedAttributes}
                                            products={this.state.products}
                                            site={this.state.site}
                                            previous="attribute"
                                        />
                                        : null}

                                </div>
                            </div>
                        </Layout>
                    </div>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default WelcomeMasterFlow